/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { incomeNoteApi } from "../../api";
import NoteCardGrid from "../../components/notes/NoteCardGrid";
import MortgageOrIncomeNote from "../../redux/interfaces/mortgageNote.redux.interface";

interface SavedNotesProps {
  props?: any;
}

const SavedNotes = ({ props }: SavedNotesProps) => {
  const [savedNotes, setSavedNotes] = useState<MortgageOrIncomeNote[]>([]);

  const [status, setStatus] = useState<string>("loading");

  const { currentUser } = useAuth();

  const fetchSavedNotes = async (showLoading = true) => {
    if (!currentUser) {
      return;
    }
    if (showLoading) setStatus("loading");
    const savedNotes = await incomeNoteApi.fetchSavedIncomeNotes(
      currentUser.id
    );
    setSavedNotes(savedNotes);
    if (showLoading) setStatus("loaded");
  };

  useEffect(() => {
    fetchSavedNotes();
  }, []);

  console.log(savedNotes);

  return (
    <>
      <div className="w-full px-4">
        <div className="mb-4">
          <h1 className="text-2xl text-heading-gray font-black">Saved Notes</h1>
        </div>
        <div className="w-full flex justify-center ">
          <NoteCardGrid
            notes={savedNotes}
            status={status}
            hideRibbon={true}
            fetchSavedNotes={fetchSavedNotes}
          />
        </div>
      </div>
    </>
  );
};

export default SavedNotes;
