import React, { useState, useEffect } from "react";
import { CreateNoteData } from "../../interfaces/incomeNote.interface";
import postNoteInputFields, { PostNoteInputField } from "./postNoteInputFields";
import { motion, AnimatePresence } from "framer-motion";
import {
  ImageUploadPreview,
  ImageItem,
} from "../../components/postNote/ImageUploadPreview";
import {
  DocumentUpload,
  DocumentItem,
} from "../../components/postNote/DocumentUpload";
import { AddressAutocomplete } from "../../components/postNote/AddressAutocomplete";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { hashNote } from "../../utils/hashNote";
import useAlert from "../../hooks/useAlert";
import { incomeNoteApi } from "../../api";
import GoogleMapsReady from "../../guards/GoogleMapsReadyGuard";

interface CreateNoteProps {
  props?: any;
}

interface ValidationErrors {
  [key: string]: string[];
}

const CreateNote = ({ props }: CreateNoteProps) => {
  const [createNoteData, setCreateNoteData] = useState<CreateNoteData>({
    loanType: "Interest Only",
    noteType: "Mortgage",
    performanceStatus: "Performing",
    lienPosition: "1st",
    originationDate: new Date(),
    maturityDate: new Date(),
    initialLoanAmount: 0,
    currentLoanAmount: 0,
    loanPaymentAmount: 0,
    totalPayoff: 0,
    estimatedMarketValue: 0,
    borrowerDownPayment: 0,
    interestRate: 0,
    loanTerm: 0,
    askingAmount: 0,
    addressData: null,
    occupancyStatus: "Owner Occupied",
    propertyType: "Single Family",
    bedrooms: 0,
    bathrooms: 0,
    squareFootage: 0,
    sellerComments: "",
    images: [],
    files: [],
    external_id: "",
    lat: null,
    lng: null,
    terms: "",
    useGoogleStreetView: false,
    acceptDocs: false,
    acceptOffers: false,
  });
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [imageItems, setImageItems] = useState<ImageItem[]>([]);
  const [documentItems, setDocumentItems] = useState<DocumentItem[]>([]);
  const [useGoogleStreetView, setUseGoogleStreetView] = useState(false);

  const navigate = useNavigate();

  const { setAlert } = useAlert();

  const [isLoading, setIsLoading] = useState(false);

  // Add effect to sync useGoogleStreetView with postNoteData
  useEffect(() => {
    setCreateNoteData((prev) => ({
      ...prev,
      useGoogleStreetView,
    }));
  }, [useGoogleStreetView]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    const field = postNoteInputFields.find((f) => f.name === name);

    if (!field) return;

    let processedValue = value;

    // Apply formatting if specified
    if (field.formatValue) {
      processedValue = field.formatValue(value);
    }

    // Validate the field
    const fieldErrors: string[] = [];
    if (field.validations) {
      field.validations.forEach((validate) => {
        const error = validate(processedValue);
        if (error) fieldErrors.push(error);
      });
    }

    setErrors((prev) => ({
      ...prev,
      [name]: fieldErrors,
    }));

    setCreateNoteData((prev: CreateNoteData) => ({
      ...prev,
      [name]: processedValue,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate all fields
    const newErrors: ValidationErrors = {};
    let hasErrors = false;

    postNoteInputFields.forEach((field) => {
      const fieldErrors: string[] = [];
      if (field.validations) {
        field.validations.forEach((validate) => {
          let error = null;
          // Special handling for file fields
          if (field.name === "images") {
            error = validate(imageItems);
          } else if (field.name === "files") {
            error = validate(documentItems);
          } else {
            error = validate(
              createNoteData[field.name as keyof CreateNoteData]
            );
          }
          if (error) fieldErrors.push(error);
        });
      }
      if (fieldErrors.length > 0) {
        hasErrors = true;
        newErrors[field.name] = fieldErrors;
      }
    });

    setErrors(newErrors);

    if (!hasErrors) {
      try {
        setIsLoading(true);
        const res = await incomeNoteApi.draft(
          createNoteData,
          imageItems,
          documentItems,
          createNoteData.addressData?.description
        );
        const noteHash = hashNote(res.id, "income");
        return navigate(`/notes/${noteHash}`, {
          state: { from: "/create-note" },
        });
      } catch (error: any) {
        console.error("Failed to post note:", error);
        // Handle error
        setAlert({
          message: error.message || "Failed to post note. Please try again.",
          type: "error",
          display: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getFieldValue = (field: PostNoteInputField, value: any): string => {
    if (field.type === "date" && value instanceof Date) {
      return value.toISOString().split("T")[0];
    }
    if (field.type === "autocomplete") {
      if (value?.description) {
        return value.description;
      }
      return "";
    }
    return String(value || "");
  };

  const getInputClassName = (hasError: boolean, baseClass: string) => {
    return `${baseClass} ${hasError ? "border-error" : ""}`;
  };

  const renderField = (field: PostNoteInputField) => {
    const fieldErrors = errors[field.name] || [];
    const hasError = fieldErrors.length > 0;

    const commonProps = {
      name: field.name,
      id: field.name,
      "aria-describedby": hasError ? `${field.name}-error` : undefined,
    };

    switch (field.type) {
      case "text":
      case "date":
        return (
          <motion.div layout>
            <input
              {...commonProps}
              type={field.type}
              value={getFieldValue(
                field,
                createNoteData[field.name as keyof CreateNoteData]
              )}
              onChange={handleChange}
              onBlur={(e) => {
                if (field.name === "bathrooms") {
                  const parts = e.target.value.split(".");
                  if (parts.length > 1) {
                    const whole = parts[0];
                    const decimal = parts[1];
                    const normalizedValue =
                      decimal && Number(decimal) >= 5 ? `${whole}.5` : whole;
                    setCreateNoteData((prev: CreateNoteData) => ({
                      ...prev,
                      [field.name]: normalizedValue,
                    }));
                  }
                }
              }}
              className={getInputClassName(hasError, "input input-sm w-full")}
              data-1p-ignore
            />
            <AnimatePresence>
              {hasError && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  className="text-error text-sm"
                >
                  {fieldErrors.join(", ")}
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        );

      case "select":
        return (
          <select
            {...commonProps}
            value={getFieldValue(
              field,
              createNoteData[field.name as keyof CreateNoteData]
            )}
            onChange={handleChange}
            className="select select-sm w-full"
            data-1p-ignore
          >
            {field.options?.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        );

      case "textarea":
        return (
          <>
            <textarea
              {...commonProps}
              value={getFieldValue(
                field,
                createNoteData[field.name as keyof CreateNoteData]
              )}
              onChange={handleChange}
              className="textarea textarea-sm w-full"
              data-1p-ignore
            />
            {hasError && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                className="text-error text-sm"
              >
                {fieldErrors.join(", ")}
              </motion.div>
            )}
          </>
        );

      case "file":
        if (field.name === "images") {
          return (
            <>
              <ImageUploadPreview
                images={imageItems}
                onChange={(newImages) => {
                  setImageItems(newImages);
                  // Clear error when images are added
                  if (newImages.length > 0) {
                    setErrors((prev) => ({
                      ...prev,
                      images: [],
                    }));
                  }
                }}
                useGoogleStreetView={useGoogleStreetView}
                onGoogleStreetViewChange={setUseGoogleStreetView}
              />
              {hasError && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  className="text-error text-sm"
                >
                  {fieldErrors.join(", ")}
                </motion.div>
              )}
            </>
          );
        } else if (field.name === "files") {
          return (
            <>
              <DocumentUpload
                documents={documentItems}
                onChange={(newDocs) => {
                  setDocumentItems(newDocs);
                  // Clear error when documents are added
                  if (newDocs.length > 0) {
                    setErrors((prev) => ({
                      ...prev,
                      files: [],
                    }));
                  }
                }}
              />
              {hasError && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  className="text-error text-sm"
                >
                  {fieldErrors.join(", ")}
                </motion.div>
              )}
            </>
          );
        }
        return null;

      case "autocomplete":
        if (field.name === "addressData") {
          return (
            <GoogleMapsReady>
              <AddressAutocomplete
                value={getFieldValue(
                  field,
                  createNoteData[field.name as keyof CreateNoteData]
                )}
                onChange={(address, latLng) => {
                  setCreateNoteData((prev: CreateNoteData) => ({
                    ...prev,
                    addressData: address,
                    lat: latLng?.lat || null,
                    lng: latLng?.lng || null,
                  }));
                  if (address?.place_id) {
                    setErrors((prev) => ({
                      ...prev,
                      addressData: [],
                    }));
                  }
                }}
                hasError={hasError}
              />
            </GoogleMapsReady>
          );
        }
        return null;

      case "checkbox":
        const isChecked = Boolean(
          createNoteData[field.name as keyof CreateNoteData]
        );
        if (field.skipLabelColumn) {
          return (
            <div className="flex items-center gap-2 mt-2">
              <input
                {...commonProps}
                type="checkbox"
                checked={isChecked}
                onChange={(e) => {
                  setCreateNoteData((prev: CreateNoteData) => ({
                    ...prev,
                    [field.name]: e.target.checked,
                  }));
                }}
                className="checkbox checkbox-sm"
              />
              <label htmlFor={field.name} className="text-sm">
                {field.label}
              </label>
            </div>
          );
        }
        return (
          <input
            {...commonProps}
            type="checkbox"
            checked={isChecked}
            onChange={(e) => {
              setCreateNoteData((prev: CreateNoteData) => ({
                ...prev,
                [field.name]: e.target.checked,
              }));
            }}
            className="checkbox checkbox-sm"
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col gap-4 w-full max-w-2xl mx-auto p-4">
      <form onSubmit={handleSubmit} className="flex flex-col gap-3">
        {postNoteInputFields.map((field) => (
          <div
            key={field.name}
            className="grid grid-cols-[200px_1fr] gap-4 items-start"
          >
            <div>
              <label htmlFor={field.name} className="text-sm font-semibold">
                {field.label}
              </label>
              {field.name === "images" && (
                <>
                  <div className="text-sm text-zinc-400 mt-1">
                    Add up to{" "}
                    <span className="font-semibold text-zinc-600">
                      {8 - imageItems.length + " "}
                    </span>
                    more images
                  </div>
                  {imageItems.length > 1 && (
                    <div className="text-sm text-zinc-400 mt-1">
                      Drag to reorder
                    </div>
                  )}
                </>
              )}
              {field.name === "files" && (
                <div className="text-sm text-zinc-400 mt-1">
                  Add up to{" "}
                  <span className="font-semibold text-zinc-600">
                    {3 - documentItems.length + " "}
                  </span>
                  more documents
                </div>
              )}
            </div>
            <div className="flex flex-col gap-1">{renderField(field)}</div>
          </div>
        ))}
        <div className="flex justify-end mt-4">
          <button type="submit" className="btn w-48 btn-accent text-lg">
            {isLoading ? <LoadingSpinner className="w-4 h-4" /> : "Save Draft"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateNote;
