import React, { useState, useEffect, useRef } from "react";
import addCommas from "../../helpers/addCommas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookmark,
  faCheck,
  faHouse,
  faLocationDot,
} from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { incomeNoteApi, notesApi } from "../../api";
import { hashNote } from "../../utils/hashNote";
import { FetchedIncomeNote } from "../../interfaces/incomeNote.interface";
import MortgageNote from "../../redux/interfaces/mortgageNote.redux.interface";
import LoadingSpinner from "../loading/LoadingSpinner";
import MortgageOrIncomeNote from "../../redux/interfaces/mortgageNote.redux.interface";
import no_image_found from "../../assets/images/no_image_found.png";

interface NoteCardProps {
  note: MortgageOrIncomeNote;
  hideRibbon?: boolean;
  hideSaved?: boolean;
  fetchSavedNotes?: (showLoading?: boolean) => void;
}

const NoteCard = ({
  note,
  hideRibbon = false,
  hideSaved = false,
  fetchSavedNotes,
}: NoteCardProps) => {
  const [cardWidth, setCardWidth] = useState<number>(0);

  const cardRef = useRef<HTMLDivElement>(null);

  const [isHovering, setIsHovering] = useState<boolean>(false);

  const [hasScrolled, setHasScrolled] = useState<boolean>(false);

  const highlightRef = useRef<HTMLParagraphElement>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  const [isUnsaving, setIsUnsaving] = useState<boolean>(false);

  const { currentUser } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (!cardRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setCardWidth(entry.contentRect.width);
      }
    });

    resizeObserver.observe(cardRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const highlightElement = highlightRef.current;
    const containerElement = containerRef.current;
    if (!highlightElement || !containerElement) return;

    const isTextOverflowing =
      highlightElement.scrollWidth > containerElement.clientWidth;

    if (!isTextOverflowing || hasScrolled) {
      highlightElement.style.transform = "translateX(0)";
      return;
    }

    let animationId: number;
    const scrollDistance =
      highlightElement.scrollWidth - containerElement.clientWidth + 80;
    const duration = scrollDistance * 9;

    const step = (timestamp: number) => {
      if (!animationId) {
        animationId = timestamp;
      }
      const elapsed = timestamp - animationId;
      const progress = Math.min(elapsed / duration, 1);

      highlightElement.style.transform = `translateX(-${
        progress * scrollDistance
      }px)`;

      if (progress < 1) {
        requestAnimationFrame(step);
      } else {
        setHasScrolled(true);
      }
    };

    if (isHovering && !hasScrolled) {
      requestAnimationFrame(step);
    }

    return () => {
      if (animationId) cancelAnimationFrame(animationId);
    };
  }, [isHovering, hasScrolled]);

  const handleMouseEnter = () => {
    setIsHovering(true);
    setHasScrolled(false);
  };

  const {
    street_number,
    city,
    state,
    postal_code,
    highlight,
    loan_amount,
    loan_to_value,
    interest_rate,
    type,
    property_image,
    loan_payment_amount,
  } = note;

  const buildAddress = (): string =>
    currentUser
      ? `${street_number} ${city}, ${state} ${postal_code}`
      : `${city}, ${state}`;

  const getBadgeClass = () => {
    if (cardWidth < 300) return "text-xs px-1.5 py-0.75";
    if (cardWidth < 400) return "text-sm px-1.5 py-0.75";
    if (cardWidth < 500) return "text-base px-2 py-1";
    return "text-lg px-2.5 py-1.5";
  };

  const getIconClass = () => {
    if (cardWidth < 300) return "w-3";
    if (cardWidth < 400) return "w-4";
    if (cardWidth < 500) return "w-5";
    return "w-6";
  };

  const onNoteClick = async (note: MortgageNote | FetchedIncomeNote) => {
    const noteHash = hashNote(note.id, note.note_data_type);
    navigate(`/notes/${noteHash}`);
  };

  const handleSaveNote = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!currentUser) return;
    setIsUnsaving(true);
    if (note.note_data_type === "mortgage") {
      await notesApi.toggleSavedNote(note.id, currentUser.id);
    } else {
      await incomeNoteApi.toggleSavedNote({
        user_id: currentUser.id,
        income_note_id: note.id,
        is_interested: false,
      });
    }
    fetchSavedNotes?.(false);
    setIsUnsaving(false);
  };

  return (
    <div
      ref={cardRef}
      className="card bg-white shadow-xl hover:cursor-pointer hover:scale-[1.01] ease-in-out transition-all duration-150 flex flex-col h-full w-full sm:w-11/12 md:w-5/6 lg:w-full xl:w-11/12 2xl:w-5/6 mx-auto"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setIsHovering(false)}
      onClick={() => onNoteClick(note)}
    >
      <figure className="relative overflow-hidden h-40 sm:h-48 md:h-56 lg:h-64 xl:h-72 2xl:h-80">
        <img
          src={property_image || no_image_found}
          alt={buildAddress()}
          className="w-full h-full object-cover"
        />
        <div
          className={`absolute bg-brown text-white rounded-md font-bold left-4 top-3 flex items-center justify-start gap-1 ${getBadgeClass()}`}
        >
          <FontAwesomeIcon icon={faHouse} className={getIconClass()} />
          {cardWidth >= 300 ? (
            <span>{type?.toUpperCase() || "SINGLE FAMILY RESIDENCE"}</span>
          ) : (
            <span>
              {(type?.toUpperCase() || "SINGLE FAMILY RESIDENCE").slice(0, 3)}
            </span>
          )}
        </div>
        {hideRibbon && !hideSaved ? (
          isHovering &&
          currentUser && (
            <div className="absolute -right-[12px] top-[8px] h-16 w-16">
              <button
                className=" btn btn-accent rounded-[12px] w-12 h-12"
                onClick={handleSaveNote}
              >
                {isUnsaving ? (
                  <LoadingSpinner className="w-4 h-4" />
                ) : (
                  <FontAwesomeIcon icon={faBookmark} />
                )}
              </button>
            </div>
          )
        ) : hideSaved ? null : (
          <div className="absolute right-0 top-0 h-16 w-16">
            <div
              className={`absolute transform rotate-45 text-center font-bold py-1 right-[-35px] top-[32px] w-[170px] bg-accent text-brown`}
            >
              {note.is_sold ? "SOLD" : "AVAILABLE"}
            </div>
          </div>
        )}
      </figure>

      <div className="relative card-body flex-grow flex flex-col justify-between p-3 sm:p-4 md:p-5 lg:p-6">
        {hideRibbon &&
          !hideSaved &&
          Boolean(note?.is_interested) &&
          currentUser && (
            <div className="absolute right-[12px] -top-[20px] ">
              <button className="btn btn-sm border-none bg-gray-200 hover:bg-gray-200 flex items-center gap-2">
                <div className="w-6 h-6 bg-accent rounded-full flex items-center justify-center">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="text-black text-sm "
                  />
                </div>
                {!("loan_number" in note)
                  ? note.accept_offers
                    ? "OFFER SUBMITTED"
                    : "DOCS SUBMITTED"
                  : "SUBMISSION RECEIVED"}
              </button>
            </div>
          )}
        <div>
          <div className="text-brown flex gap-1.5 items-center justify-start mb-2">
            <FontAwesomeIcon icon={faLocationDot} className="" />
            <span>{buildAddress()}</span>
          </div>
          <div ref={containerRef} className="relative overflow-hidden mb-4">
            <p
              ref={highlightRef}
              className="text-brown whitespace-nowrap transition-transform duration-300 ease-linear"
              style={{ paddingRight: "100px" }}
            >
              {highlight}
            </p>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between mb-4">
            <div className="flex flex-col columns-1 justify-start items-start">
              <div className="text-secondary font-bold">LOAN AMT</div>
              <div className="text-brown font-bold">{`$${addCommas(
                loan_amount || 0
              )}`}</div>
            </div>
            <div className="flex flex-col columns-1 justify-start items-start">
              <div className="text-secondary font-bold">TARGETED ROR</div>
              <div className="text-brown font-bold">{`${
                interest_rate?.toFixed(2) || "?"
              }%`}</div>
            </div>
            <div className="flex flex-col columns-1 justify-start items-start">
              <div className="text-secondary font-bold">LTV</div>
              <div className="text-brown font-bold">{`${loan_to_value}%`}</div>
            </div>
          </div>
          <div className="h-[1px] bg-brown rounded-full mb-4"></div>
          <div className="flex items-center justify-between">
            <div className="text-secondary font-bold">
              TARGETED MONTHLY INCOME
            </div>
            <div className="text-brown font-bold">{`$ ${loan_payment_amount}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteCard;
