import React, { useState, useEffect } from "react";
import { CreateNoteData } from "../../interfaces/incomeNote.interface";
import postNoteInputFields, { PostNoteInputField } from "./postNoteInputFields";
import { motion, AnimatePresence } from "framer-motion";
import {
  ImageUploadPreview,
  ImageItem,
} from "../../components/postNote/ImageUploadPreview";
import {
  DocumentUpload,
  DocumentItem,
} from "../../components/postNote/DocumentUpload";
import { AddressAutocomplete } from "../../components/postNote/AddressAutocomplete";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import { useNavigate, useParams } from "react-router-dom";
import { unhashNote } from "../../utils/hashNote";
import useAlert from "../../hooks/useAlert";
import { incomeNoteApi } from "../../api";

interface EditNoteProps {
  props?: any;
}

interface ValidationErrors {
  [key: string]: string[];
}

const EditNote = ({ props }: EditNoteProps) => {
  const { noteHash } = useParams<{ noteHash: string }>();
  const [postNoteData, setPostNoteData] = useState<CreateNoteData>({
    loanType: "Interest Only",
    noteType: "Mortgage",
    performanceStatus: "Performing",
    lienPosition: "1st",
    originationDate: new Date(),
    maturityDate: new Date(),
    initialLoanAmount: 0,
    currentLoanAmount: 0,
    loanPaymentAmount: 0,
    totalPayoff: 0,
    estimatedMarketValue: 0,
    borrowerDownPayment: 0,
    interestRate: 0,
    loanTerm: 0,
    askingAmount: 0,
    addressData: null,
    lat: null,
    lng: null,
    terms: "",
    occupancyStatus: "Owner Occupied",
    propertyType: "Single Family",
    bedrooms: 0,
    bathrooms: 0,
    squareFootage: 0,
    sellerComments: "",
    images: [],
    files: [],
    acceptDocs: false,
    acceptOffers: false,
  });
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [imageItems, setImageItems] = useState<ImageItem[]>([]);
  const [documentItems, setDocumentItems] = useState<DocumentItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingNote, setIsFetchingNote] = useState(true);
  const [useGoogleStreetView, setUseGoogleStreetView] = useState(false);

  const navigate = useNavigate();
  const { setAlert } = useAlert();

  const [fullAddress, setFullAddress] = useState<string | null>(null);

  // Add effect to sync useGoogleStreetView with postNoteData
  useEffect(() => {
    setPostNoteData((prev: CreateNoteData) => ({
      ...prev,
      useGoogleStreetView,
    }));
  }, [useGoogleStreetView]);

  useEffect(() => {
    const fetchNote = async () => {
      if (!noteHash) return;

      try {
        setIsFetchingNote(true);
        const { noteID } = unhashNote(noteHash);
        const note = await incomeNoteApi.fetchIncomeNote(noteID);

        // Convert the note data to PostNoteData format
        setPostNoteData({
          loanType: note.loan_type,
          noteType: note.note_type,
          performanceStatus: note.performance_status,
          lienPosition: note.lien_position,
          originationDate: new Date(note.origination_date),
          maturityDate: new Date(note.maturity_date),
          initialLoanAmount: note.initial_loan_amount,
          currentLoanAmount: note.current_loan_amount,
          loanPaymentAmount: note.loan_payment_amount,
          totalPayoff: note.total_payoff,
          estimatedMarketValue: note.estimated_market_value,
          borrowerDownPayment: note.borrower_down_payment,
          interestRate: note.interest_rate,
          loanTerm: note.loan_term || 0,
          askingAmount: note.asking_amount,
          addressData: {
            description: note.full_address,
            place_id: note.google_place_id,
          } as any,
          lat: note.lat || null,
          lng: note.lng || null,
          terms: note.terms,
          occupancyStatus: note.occupancy_status,
          propertyType: note.property_type,
          bedrooms: note.bedrooms,
          bathrooms: note.bathrooms,
          squareFootage: note.square_footage,
          sellerComments: note.seller_comments,
          external_id: note.external_id,
          images: [],
          files: [],
          acceptDocs: note.accept_docs,
          acceptOffers: note.accept_offers,
        });
        setFullAddress(note.full_address);

        // Convert existing images to ImageItems
        const convertedImages: ImageItem[] = note.images.map((img) => {
          // Create a filename from the URL
          const filename = img.url.split("/").pop() || "";

          // Add cache-busting parameter to the URL
          const cacheBustUrl = `${img.url}?t=${Date.now()}`;

          // Create a placeholder File object with the correct name
          const file = new File([new Blob()], filename, {
            type: "image/png", // Set appropriate mime type
          });

          return {
            id: img.id.toString(),
            file,
            preview: cacheBustUrl,
            alt: img.alt,
            existing: true,
          };
        });
        setImageItems(convertedImages);

        // Convert existing documents to DocumentItems
        const convertedDocs: DocumentItem[] = note.documents.map((doc) => {
          // Create a filename from the URL
          const filename = doc.url.split("/").pop() || "";

          // Add cache-busting parameter to the URL
          const cacheBustUrl = `${doc.url}?t=${Date.now()}`;

          // Create a placeholder File object with the correct name
          const file = new File([new Blob()], filename, {
            type: "application/pdf", // Set appropriate mime type
          });

          return {
            id: doc.id.toString(),
            file,
            preview: cacheBustUrl,
            type: doc.document_type,
            existing: true,
          };
        });
        setDocumentItems(convertedDocs);
      } catch (error: any) {
        console.error("Failed to fetch note:", error);
        setAlert({
          message: error.message || "Failed to fetch note. Please try again.",
          type: "error",
          display: true,
        });
      } finally {
        setIsFetchingNote(false);
      }
    };

    fetchNote();
  }, [noteHash, setAlert]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    const field = postNoteInputFields.find((f) => f.name === name);

    if (!field) return;

    let processedValue = value;

    // Apply formatting if specified
    if (field.formatValue) {
      processedValue = field.formatValue(value);
    }

    // Validate the field
    const fieldErrors: string[] = [];
    if (field.validations) {
      field.validations.forEach((validate) => {
        const error = validate(processedValue);
        if (error) fieldErrors.push(error);
      });
    }

    setErrors((prev) => ({
      ...prev,
      [name]: fieldErrors,
    }));

    setPostNoteData((prev: CreateNoteData) => ({
      ...prev,
      [name]: processedValue,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate all fields
    const newErrors: ValidationErrors = {};
    let hasErrors = false;

    postNoteInputFields.forEach((field) => {
      const fieldErrors: string[] = [];
      if (field.validations) {
        field.validations.forEach((validate) => {
          let error = null;
          // Special handling for file fields
          if (field.name === "images") {
            error = validate(imageItems);
          } else if (field.name === "files") {
            error = validate(documentItems);
          } else {
            error = validate(postNoteData[field.name as keyof CreateNoteData]);
          }
          if (error) fieldErrors.push(error);
        });
      }
      if (fieldErrors.length > 0) {
        hasErrors = true;
        newErrors[field.name] = fieldErrors;
      }
    });

    setErrors(newErrors);

    if (!hasErrors && noteHash) {
      try {
        setIsLoading(true);
        const { noteID } = unhashNote(noteHash);

        // Separate new and existing files
        const newImages = imageItems.filter((img) => !img.existing);
        const existingImages = imageItems.filter((img) => img.existing);

        const newDocuments = documentItems.filter((doc) => !doc.existing);
        const existingDocuments = documentItems.filter((doc) => doc.existing);

        // Create metadata for both new and existing files
        const imageMetadata = {
          ...existingImages.reduce(
            (acc, img) => ({
              ...acc,
              [img.id]: {
                alt: img.alt,
                existing: true,
                url: (img.preview || "").split("?")[0], // Add null check
              },
            }),
            {}
          ),
          ...newImages.reduce(
            (acc, img) => ({
              ...acc,
              [img.file.name]: {
                alt: img.alt,
                existing: false,
              },
            }),
            {}
          ),
        };

        const documentMetadata = {
          ...existingDocuments.reduce(
            (acc, doc) => ({
              ...acc,
              [doc.id]: {
                documentType: doc.type,
                existing: true,
                url: (doc.preview || "").split("?")[0], // Add null check
              },
            }),
            {}
          ),
          ...newDocuments.reduce(
            (acc, doc) => ({
              ...acc,
              [doc.file.name]: {
                documentType: doc.type,
                existing: false,
              },
            }),
            {}
          ),
        };

        // Update postNoteData with the metadata
        const updatedPostNoteData = {
          ...postNoteData,
          image_metadata: imageMetadata,
          document_metadata: documentMetadata,
          // Add arrays of all files (both existing and new)
          images: [...existingImages, ...newImages],
          files: [...existingDocuments, ...newDocuments],
        };

        // Send all files to the backend
        await incomeNoteApi.update(
          noteID,
          updatedPostNoteData,
          [...existingImages, ...newImages],
          [...existingDocuments, ...newDocuments],
          fullAddress ? fullAddress : undefined
        );
        navigate("/my-notes");
      } catch (error: any) {
        console.error("Failed to update note:", error);
        setAlert({
          message: error.message || "Failed to update note. Please try again.",
          type: "error",
          display: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getFieldValue = (field: PostNoteInputField, value: any): string => {
    if (field.type === "date" && value instanceof Date) {
      return value.toISOString().split("T")[0];
    }
    if (field.type === "autocomplete") {
      if (value?.description) {
        return value.description;
      }
      return "";
    }
    // Special handling for numeric fields to show 0 when value is 0
    if (field.inputMode === "numeric" || field.inputMode === "decimal") {
      return value === 0 ? "0" : String(value || "");
    }
    return String(value || "");
  };

  const getInputClassName = (hasError: boolean, baseClass: string) => {
    return `${baseClass} ${hasError ? "border-error" : ""}`;
  };

  const renderField = (field: PostNoteInputField) => {
    const fieldErrors = errors[field.name] || [];
    const hasError = fieldErrors.length > 0;

    const commonProps = {
      name: field.name,
      id: field.name,
      "aria-describedby": hasError ? `${field.name}-error` : undefined,
    };

    switch (field.type) {
      case "text":
      case "date":
        return (
          <motion.div layout>
            <input
              {...commonProps}
              type={field.type}
              value={getFieldValue(
                field,
                postNoteData[field.name as keyof CreateNoteData]
              )}
              onChange={handleChange}
              onBlur={(e) => {
                if (field.name === "bathrooms") {
                  const parts = e.target.value.split(".");
                  if (parts.length > 1) {
                    const whole = parts[0];
                    const decimal = parts[1];
                    const normalizedValue =
                      decimal && Number(decimal) >= 5 ? `${whole}.5` : whole;
                    setPostNoteData((prev: CreateNoteData) => ({
                      ...prev,
                      [field.name]: normalizedValue,
                    }));
                  }
                }
              }}
              className={getInputClassName(hasError, "input input-sm w-full")}
              data-1p-ignore
            />
            <AnimatePresence>
              {hasError && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  className="text-error text-sm"
                >
                  {fieldErrors.join(", ")}
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        );

      case "select":
        return (
          <select
            {...commonProps}
            value={getFieldValue(
              field,
              postNoteData[field.name as keyof CreateNoteData]
            )}
            onChange={handleChange}
            className="select select-sm w-full"
            data-1p-ignore
          >
            {field.options?.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        );

      case "textarea":
        return (
          <>
            <textarea
              {...commonProps}
              value={getFieldValue(
                field,
                postNoteData[field.name as keyof CreateNoteData]
              )}
              onChange={handleChange}
              className="textarea textarea-sm w-full"
              data-1p-ignore
            />
            {hasError && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                className="text-error text-sm"
              >
                {fieldErrors.join(", ")}
              </motion.div>
            )}
          </>
        );

      case "file":
        if (field.name === "images") {
          return (
            <>
              <ImageUploadPreview
                images={imageItems}
                onChange={(newImages) => {
                  setImageItems(newImages);
                  // Clear error when images are added
                  if (newImages.length > 0) {
                    setErrors((prev) => ({
                      ...prev,
                      images: [],
                    }));
                  }
                }}
                useGoogleStreetView={useGoogleStreetView}
                onGoogleStreetViewChange={setUseGoogleStreetView}
              />
              {hasError && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  className="text-error text-sm"
                >
                  {fieldErrors.join(", ")}
                </motion.div>
              )}
            </>
          );
        } else if (field.name === "files") {
          return (
            <>
              <DocumentUpload
                documents={documentItems}
                onChange={(newDocs) => {
                  setDocumentItems(newDocs);
                  // Clear error when documents are added
                  if (newDocs.length > 0) {
                    setErrors((prev) => ({
                      ...prev,
                      files: [],
                    }));
                  }
                }}
              />
              {hasError && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  className="text-error text-sm"
                >
                  {fieldErrors.join(", ")}
                </motion.div>
              )}
            </>
          );
        }
        return null;

      case "autocomplete":
        if (field.name === "addressData") {
          return (
            <AddressAutocomplete
              value={getFieldValue(
                field,
                postNoteData[field.name as keyof CreateNoteData]
              )}
              onChange={(address, latLng) => {
                setPostNoteData((prev: CreateNoteData) => ({
                  ...prev,
                  addressData: address,
                  lat: latLng?.lat || null,
                  lng: latLng?.lng || null,
                }));
                if (address?.place_id) {
                  setErrors((prev) => ({
                    ...prev,
                    addressData: [],
                  }));
                }
              }}
              hasError={hasError}
            />
          );
        }
        return null;

      case "checkbox":
        const isChecked = Boolean(
          postNoteData[field.name as keyof CreateNoteData]
        );
        if (field.skipLabelColumn) {
          return (
            <div className="flex items-center gap-2 mt-2">
              <input
                {...commonProps}
                type="checkbox"
                checked={isChecked}
                onChange={(e) => {
                  setPostNoteData((prev: CreateNoteData) => ({
                    ...prev,
                    [field.name]: e.target.checked,
                  }));
                }}
                className="checkbox checkbox-sm"
              />
              <label htmlFor={field.name} className="text-sm">
                {field.label}
              </label>
            </div>
          );
        }
        return (
          <input
            {...commonProps}
            type="checkbox"
            checked={isChecked}
            onChange={(e) => {
              setPostNoteData((prev: CreateNoteData) => ({
                ...prev,
                [field.name]: e.target.checked,
              }));
            }}
            className="checkbox checkbox-sm"
          />
        );

      default:
        return null;
    }
  };

  if (isFetchingNote) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <LoadingSpinner className="w-8 h-8" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 w-full max-w-2xl mx-auto p-4">
      <form onSubmit={handleSubmit} className="flex flex-col gap-3">
        {postNoteInputFields.map((field) => (
          <div
            key={field.name}
            className="grid grid-cols-[200px_1fr] gap-4 items-start"
          >
            <div>
              <label htmlFor={field.name} className="text-sm font-semibold">
                {field.label}
              </label>
              {field.name === "images" && (
                <>
                  <div className="text-sm text-zinc-400 mt-1">
                    Add up to{" "}
                    <span className="font-semibold text-zinc-600">
                      {8 - imageItems.length + " "}
                    </span>
                    more images
                  </div>
                  {imageItems.length > 1 && (
                    <div className="text-sm text-zinc-400 mt-1">
                      Drag to reorder
                    </div>
                  )}
                </>
              )}
              {field.name === "files" && (
                <div className="text-sm text-zinc-400 mt-1">
                  Add up to{" "}
                  <span className="font-semibold text-zinc-600">
                    {3 - documentItems.length + " "}
                  </span>
                  more documents
                </div>
              )}
            </div>
            <div className="flex flex-col gap-1">{renderField(field)}</div>
          </div>
        ))}
        <div className="flex justify-end mt-4">
          <button type="submit" className="btn w-48 btn-accent text-lg">
            {isLoading ? (
              <LoadingSpinner className="w-4 h-4" />
            ) : (
              "Save Changes"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditNote;
