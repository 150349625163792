import React, { useEffect } from "react";
import Routes from "./routes";
import "./App.css";
import configs from "./configs";
import ApplicationProvider from "./provider";
import HubSpotTracker from "./components/hubspot/HubSpotTracker";
import loadGoogleMapsApi from "./scripts/loadGoogleMapsApi";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AppErrorBoundary from "./components/error/ErrorBoundary";

function App() {
  useEffect(() => {
    loadGoogleMapsApi();
  }, []);

  const isProduction = process.env.NODE_ENV === "production";

  return (
    <AppErrorBoundary
      showDialog={!isProduction}
      onError={(error, errorInfo) => {
        if (!isProduction) {
          console.error("Error caught by error boundary:", error);
          console.error("Component stack:", errorInfo.componentStack);
        }
      }}
    >
      <HelmetProvider>
        <Helmet>
          <title>{configs.name}</title>
        </Helmet>
        <ApplicationProvider>
          <HubSpotTracker />
          <Routes />
        </ApplicationProvider>
      </HelmetProvider>
    </AppErrorBoundary>
  );
}

export default App;
