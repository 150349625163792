import React, { ReactNode, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import LoadingTable from "../components/loading/LoadingTable";
import {
  getRequiredPermissionsForPath,
  hasRequiredPermissions,
} from "./permissions";
import { setSentryUser } from "../lib/sentry";
interface PrivateRouteProps {
  children?: ReactNode;
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { currentUser, isLoading } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (currentUser) {
      try {
        setSentryUser({
          id: currentUser?.id.toString() || "",
          email: currentUser?.email || "",
        });
      } catch (error) {
        console.error("Failed to parse user data:", error);
      }
    }
  }, [currentUser]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <div className="bg-base-300 rounded-lg px-4 py-1 mb-48">
          <LoadingTable label="" />
        </div>
      </div>
    );
  }

  if (!currentUser) {
    return <Navigate to="/" />;
  }

  const requiredPermissions = getRequiredPermissionsForPath(location.pathname);
  if (
    requiredPermissions.length > 0 &&
    !hasRequiredPermissions(currentUser, requiredPermissions)
  ) {
    return <Navigate to="/" replace />;
  }

  return <>{children || <Outlet />}</>;
};

export default PrivateRoute;
