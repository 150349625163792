const GOOGLE_MAPS_SRC = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&loading=async`;

export default function loadGoogleMapsApi(): Promise<void> {
  if (window.google && window.google.maps) return Promise.resolve();

  return new Promise((resolve, reject) => {
    const scriptExists = document.querySelector(
      `script[src^="${GOOGLE_MAPS_SRC}"]`
    );
    if (scriptExists) {
      scriptExists.addEventListener("load", () => resolve());
      return;
    }

    const script = document.createElement("script");
    script.src = GOOGLE_MAPS_SRC;
    script.async = true;
    script.defer = true;
    script.setAttribute("loading", "async");

    script.onload = () => resolve();
    script.onerror = (err) => reject(err);

    document.body.appendChild(script);
  });
}
