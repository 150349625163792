// import { DocumentItem } from "../../components/postNote/DocumentUpload";
// import { ImageItem } from "../../components/postNote/ImageUploadPreview";
import {
  validateRequired,
  validateRange,
  validatePastDate,
  formatPercentage,
  formatCurrencyInput,
} from "../../utils/inputUtils";

export interface PostNoteInputField {
  name: string;
  label: string;
  type:
    | "text"
    | "date"
    | "select"
    | "textarea"
    | "file"
    | "autocomplete"
    | "checkbox";
  inputMode?: "numeric" | "decimal" | "text";
  validations?: Array<(value: any) => string | null>;
  options?: string[];
  accept?: string;
  multiple?: boolean;
  formatValue?: (value: string) => string;
  placeholder?: string;
  displayInline?: boolean;
  displayBelowField?: string;
  skipLabelColumn?: boolean;
}

const formatBathrooms = (value: string): string => {
  // Remove any non-digit and non-decimal characters
  const cleanValue = value.replace(/[^\d.]/g, "");
  const parts = cleanValue.split(".");

  // Handle the decimal part
  if (parts.length > 1) {
    const whole = parts[0];
    const decimal = parts[1];

    // Allow typing but normalize on blur
    if (decimal === "") {
      return `${whole}.`; // Allow typing decimal point
    }

    // Only allow .5
    if (decimal && Number(decimal) >= 5) {
      return `${whole}.5`;
    } else if (decimal) {
      return `${whole}.${decimal[0]}`; // Allow typing but limit to one decimal
    }
  }

  return parts[0] || "";
};

const formatInteger = (value: string): string => {
  // Remove any non-digit characters
  return value.replace(/[^\d]/g, "");
};

const safeNumber = (value: any): number => {
  if (!value) return 0;
  if (typeof value === "string") {
    return Number(value.replace(/[^\d.]/g, "")) || 0;
  }
  return Number(value) || 0;
};

// const validateImages = (images: ImageItem[]): string | null => {
//   if (!images || images.length === 0) {
//     return "At least one image is required";
//   }
//   return null;
// };

// const validateDocuments = (documents: DocumentItem[]): string | null => {
//   if (!documents || documents.length === 0) {
//     return "At least one document is required";
//   }
//   return null;
// };

const postNoteInputFields: PostNoteInputField[] = [
  // Property Information Section
  {
    name: "addressData",
    label: "Address",
    type: "autocomplete",
    options: [],
    validations: [validateRequired],
  },
  {
    name: "external_id",
    label: "External ID",
    type: "text",
    inputMode: "text",
    placeholder: "Enter external ID",
  },
  {
    name: "propertyType",
    label: "Property Type",
    type: "select",
    options: [
      "Single Family",
      "Duplex",
      "Multi Unit 3+",
      "Townhouse",
      "Condo",
      "Land",
      "Other",
    ],
    validations: [],
  },
  {
    name: "occupancyStatus",
    label: "Occupancy Status",
    type: "select",
    options: ["Owner Occupied", "Non Owner Occupied", "Vacant", "Unknown"],
    validations: [],
  },
  {
    name: "performanceStatus",
    label: "Note Performance",
    type: "select",
    options: ["Performing", "UnderPerforming", "NonPerforming"],
    validations: [],
  },
  {
    name: "bedrooms",
    label: "Bedrooms",
    type: "text",
    inputMode: "decimal",
    validations: [
      validateRequired,
      (value) => validateRange(Number(value), 0, 20),
    ],
    formatValue: formatInteger,
  },
  {
    name: "bathrooms",
    label: "Bathrooms",
    type: "text",
    inputMode: "decimal",
    validations: [
      validateRequired,
      (value) => validateRange(Number(value), 0, 20),
    ],
    formatValue: formatBathrooms,
  },
  {
    name: "squareFootage",
    label: "Square Footage",
    type: "text",
    inputMode: "numeric",
    validations: [
      validateRequired,
      (value) => validateRange(Number(value), 0, 100000),
    ],
    formatValue: formatInteger,
  },
  {
    name: "estimatedMarketValue",
    label: "Estimated Market Value ($)",
    type: "text",
    inputMode: "decimal",
    validations: [
      validateRequired,
      (value) => validateRange(safeNumber(value), 0, 10000000),
    ],
    formatValue: formatCurrencyInput,
  },

  // Loan Details Section
  {
    name: "loanType",
    label: "Loan Type",
    type: "select",
    options: ["Interest Only", "Amortized Loan"],
    validations: [],
  },
  {
    name: "noteType",
    label: "Note Type",
    type: "select",
    options: ["Mortgage", "Deed of Trust", "Contract For Deed"],
    validations: [],
  },
  {
    name: "lienPosition",
    label: "Lien Position",
    type: "select",
    options: ["1st", "2nd", "3rd"],
    validations: [],
  },

  // Loan Terms Section
  {
    name: "originationDate",
    label: "Origination Date",
    type: "date",
    validations: [validateRequired, validatePastDate],
  },
  {
    name: "maturityDate",
    label: "Maturity Date",
    type: "date",
    validations: [validateRequired],
    // validations: [validateRequired, validateFutureDate],
  },
  {
    name: "initialLoanAmount",
    label: "Initial Loan Amount ($)",
    type: "text",
    inputMode: "decimal",
    validations: [
      validateRequired,
      (value) => validateRange(safeNumber(value), 1, 10000000),
    ],
    formatValue: formatCurrencyInput,
  },
  {
    name: "currentLoanAmount",
    label: "Current Loan Amount ($)",
    type: "text",
    inputMode: "decimal",
    validations: [
      validateRequired,
      (value) => validateRange(safeNumber(value), 1, 10000000),
    ],
    formatValue: formatCurrencyInput,
  },
  {
    name: "borrowerDownPayment",
    label: "Borrower Down Payment ($)",
    type: "text",
    inputMode: "decimal",
    validations: [
      validateRequired,
      (value) => validateRange(safeNumber(value), -1000000, 10000000),
    ],
    formatValue: formatCurrencyInput,
  },
  {
    name: "interestRate",
    label: "Interest Rate (%)",
    type: "text",
    inputMode: "decimal",
    validations: [
      validateRequired,
      (value) => validateRange(Number(value), 0, 100),
    ],
    formatValue: formatPercentage,
  },
  {
    name: "loanTerm",
    label: "Loan Term (months)",
    type: "text",
    inputMode: "decimal",
    validations: [
      validateRequired,
      (value) => validateRange(Number(value), 0, 900),
    ],
    formatValue: formatInteger,
  },
  {
    name: "loanPaymentAmount",
    label: "Loan Payment Amount ($)",
    type: "text",
    inputMode: "decimal",
    validations: [
      validateRequired,
      (value) => validateRange(safeNumber(value), 0, 1000000),
    ],
    formatValue: formatCurrencyInput,
  },
  {
    name: "totalPayoff",
    label: "Total Payoff ($)",
    type: "text",
    inputMode: "decimal",
    validations: [
      validateRequired,
      (value) => validateRange(safeNumber(value), 0, 10000000),
    ],
    formatValue: formatCurrencyInput,
  },
  {
    name: "askingAmount",
    label: "Asking Amount ($)",
    type: "text",
    inputMode: "decimal",
    validations: [validateRequired],
    formatValue: formatCurrencyInput,
  },

  // Additional Information Section
  {
    name: "sellerComments",
    label: "Seller Comments",
    type: "textarea",
    placeholder: "Enter any additional information about the property or note",
    // validations: [validateRequired],
  },
  {
    name: "terms",
    label: "Terms And Conditions",
    type: "textarea",
    placeholder: "Enter any specific terms you would like to include",
    // validations: [validateRequired],
  },
  {
    name: "images",
    label: "Property Images",
    type: "file",
    accept: "image/*",
    multiple: true,
    // validations: [validateImages],
  },
  {
    name: "files",
    label: "Supporting Documents",
    type: "file",
    accept: ".pdf,.doc,.docx",
    multiple: true,
    // validations: [validateDocuments],
  },
  {
    name: "acceptDocs",
    label: "Accept Documents",
    type: "checkbox",
    validations: [],
  },
  {
    name: "acceptOffers",
    label: "Accept Offers",
    type: "checkbox",
    validations: [],
  },
];

export default postNoteInputFields;
