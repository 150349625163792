/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { userApi } from "../api";
import LoadingTable from "../components/loading/LoadingTable";

interface PasswordResetGuardProps {
  children: ReactNode;
}

const PasswordResetGuard = ({ children }: PasswordResetGuardProps) => {
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      if (!token) {
        setIsLoading(false);
        navigate("/");
      }
      try {
        if (!token) {
          setIsLoading(false);

          return;
        }
        setIsLoading(true);
        const resp: { success: boolean; message: string } =
          await userApi.verifyPasswordResetToken(token);
        if (!resp.success) {
          setIsLoading(false);
          return navigate("/invalid", {
            state: { message: resp.message },
          });
        }
        setIsLoading(false);
        localStorage.setItem("passwordResetToken", token);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    verifyToken();
  }, [token]);

  if (!token) {
    return <Navigate to="/" />;
  }

  if (isLoading) {
    return (
      <>
        <div className="flex flex-col w-full justify-center items-center">
          <LoadingTable label="Verifying Token" displayLoading={false} />
        </div>
      </>
    );
  }

  return <>{children}</>;
};

export default PasswordResetGuard;
