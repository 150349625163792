import { useRoutes } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import configs from "./configs";
import Notes from "./pages/Notes";
import NoteGuard from "./guards/NoteGuard";
import NotFound from "./pages/NotFound";
import Acknowledgement from "./pages/Acknowledgement";
import CreateNote from "./pages/private/CreateNote";
import MyNotes from "./pages/private/MyNotes";
import PrivateLayout from "./layouts/PrivateLayout";
import SharedLayout from "./layouts/SharedLayout";
import MyInfo from "./pages/private/MyInfo";
import SavedNotes from "./pages/private/SavedNotes";
import EditNote from "./pages/private/EditNote";
import PasswordResetGuard from "./guards/PasswordResetGuard";
import ResetPassword from "./pages/ResetPassword";
import Invalid from "./pages/Invalid";
import Login from "./pages/Login";

const Routes = () => {
  const routing = useRoutes([
    {
      // eslint-disable-next-line react/jsx-no-undef
      element: <SharedLayout />,
      children: [
        {
          path: "/",
          element: (
            <>
              <Helmet>
                <title>{configs.name}</title>
              </Helmet>
              <Notes />
            </>
          ),
        },
        {
          path: "/notes/:noteHash",
          element: (
            <>
              <Helmet>
                <title>{configs.name}</title>
              </Helmet>
              <NoteGuard />
            </>
          ),
        },
        {
          path: "/terms-and-conditions",
          element: (
            <>
              <Acknowledgement />
            </>
          ),
        },
        {
          path: "/reset-password/:token",
          element: (
            <>
              <PasswordResetGuard>
                <ResetPassword />
              </PasswordResetGuard>
            </>
          ),
        },
        {
          path: "/invalid",
          element: <Invalid message={window.history.state?.usr?.message} />,
        },
        {
          path: "/login",
          element: <Login message={window.history.state?.usr?.message} />,
        },
      ],
    },
    {
      element: <PrivateLayout />,
      children: [
        {
          path: "/saved-notes",
          element: (
            <>
              <Helmet>
                <title>{configs.name}</title>
              </Helmet>
              <SavedNotes />
            </>
          ),
        },
        {
          path: "/create-note",
          element: (
            <>
              <Helmet>
                <title>{configs.name}</title>
              </Helmet>
              <CreateNote />
            </>
          ),
        },
        {
          path: "/my-notes",
          element: (
            <>
              <Helmet>
                <title>{configs.name}</title>
              </Helmet>
              <MyNotes />
            </>
          ),
        },
        {
          path: "/my-info",
          element: (
            <>
              <Helmet>
                <title>{configs.name}</title>
              </Helmet>
              <MyInfo />
            </>
          ),
        },
        {
          path: "/notes/:noteHash/edit",
          element: (
            <>
              <Helmet>
                <title>{configs.name}</title>
              </Helmet>
              <EditNote />
            </>
          ),
        },
      ],
    },
    {
      path: "*",
      element: (
        <>
          <NotFound />
        </>
      ),
    },
  ]);

  return (
    <>
      <Helmet>
        <title>{configs.name || window.location.host}</title>
      </Helmet>
      {routing}
    </>
  );
};

export default Routes;
