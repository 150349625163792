import * as Sentry from "@sentry/react";
import { browserTracingIntegration, replayIntegration } from "@sentry/react";

export const initSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: true,
      }),
      browserTracingIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: parseFloat(
      process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || "0.2"
    ),
    // Session Replay
    replaysSessionSampleRate: parseFloat(
      process.env.REACT_APP_SENTRY_REPLY_SESSION_SAMPLE_RATE || "0.1"
    ),
    replaysOnErrorSampleRate: parseFloat(
      process.env.REACT_APP_SENTRY_REPLY_ON_ERROR_SAMPLE_RATE || "1.0"
    ),
    // Environment and release info
    environment: process.env.NODE_ENV,
    release: process.env.REACT_APP_VERSION || "development",
    beforeSend(event) {
      if (process.env.NODE_ENV === "development") {
        return null;
      }

      if (event.exception) {
        const exceptionValue = event.exception.values?.[0]?.value;
        if (exceptionValue?.includes("ResizeObserver loop")) {
          return null;
        }
      }

      return event;
    },
    sendDefaultPii: true,
    maxBreadcrumbs: 50,
  });
};

export const setSentryUser = (user: {
  id: string;
  email?: string;
  username?: string;
}) => {
  Sentry.setUser(user);
};

export const addSentryBreadcrumb = (
  category: string,
  message: string,
  level: Sentry.SeverityLevel = "info"
) => {
  Sentry.addBreadcrumb({
    category,
    message,
    level,
  });
};

export const setSentryContext = (
  name: string,
  context: Record<string, any>
) => {
  Sentry.setContext(name, context);
};
