import { useEffect, useState } from "react";
import loadGoogleMapsApi from "../scripts/loadGoogleMapsApi";

interface GoogleMapsReadyProps {
  children: React.ReactNode;
}

const GoogleMapsReady = ({ children }: GoogleMapsReadyProps) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    loadGoogleMapsApi().then(() => {
      setReady(true);
    });
  }, []);

  return ready ? <>{children}</> : null;
};

export default GoogleMapsReady;
