import React, {
  ReactNode,
  useState,
  useEffect,
  useCallback,
  ComponentType,
} from "react";
import * as Sentry from "@sentry/react";

interface Props {
  children: ReactNode;
  fallbackComponent?: ComponentType<{
    error: Error;
    resetError: () => void;
  }>;
  onError?: (error: Error, errorInfo: { componentStack: string }) => void;
  resetKeys?: any[];
  showDialog?: boolean;
}

interface ErrorState {
  error: Error | null;
  errorInfo: { componentStack: string } | null;
}

const isProduction = process.env.NODE_ENV === "production";

export const AppErrorBoundary: React.FC<Props> = ({
  children,
  fallbackComponent: FallbackComponent,
  onError,
  resetKeys = [],
  showDialog = false,
}) => {
  const [errorState, setErrorState] = useState<ErrorState>({
    error: null,
    errorInfo: null,
  });
  const [prevResetKeys, setPrevResetKeys] = useState<any[]>(resetKeys);

  const resetError = useCallback(() => {
    setErrorState({ error: null, errorInfo: null });
  }, []);

  // Reset error when resetKeys change
  useEffect(() => {
    const changed =
      prevResetKeys.length !== resetKeys.length ||
      prevResetKeys.some((key, i) => key !== resetKeys[i]);

    if (changed) {
      resetError();
      setPrevResetKeys(resetKeys);
    }
  }, [resetKeys, prevResetKeys, resetError]);

  useEffect(() => {
    // Add navigation breadcrumb
    Sentry.addBreadcrumb({
      category: "navigation",
      message: `Navigated to ${window.location.pathname}`,
      level: "info",
    });
  }, []);

  if (errorState.error) {
    // Show Sentry dialog in development
    if (showDialog && !isProduction) {
      Sentry.showReportDialog({
        eventId: Sentry.captureException(errorState.error),
      });
    }

    if (FallbackComponent) {
      return (
        <FallbackComponent error={errorState.error} resetError={resetError} />
      );
    }

    // Default fallback UI
    return (
      <div style={{ padding: 24, fontFamily: "sans-serif", color: "#333" }}>
        <h1>Something went wrong</h1>
        <p>
          The application encountered an unexpected error. Please try again.
        </p>
        {!isProduction && (
          <>
            <pre style={{ whiteSpace: "pre-wrap", color: "red" }}>
              {errorState.error.toString()}
            </pre>
            {errorState.errorInfo && (
              <pre style={{ whiteSpace: "pre-wrap", color: "#666" }}>
                Component Stack Trace:
                {errorState.errorInfo.componentStack}
              </pre>
            )}
          </>
        )}
        <button onClick={resetError} style={{ marginRight: 8 }}>
          Try Again
        </button>
        <button
          onClick={() => window.location.reload()}
          style={{ marginLeft: 8 }}
        >
          Reload Page
        </button>
      </div>
    );
  }

  return (
    <ErrorCatcher
      onError={(error, errorInfo) => {
        setErrorState({ error, errorInfo });
        if (onError) onError(error, errorInfo);

        // Add error context to Sentry
        Sentry.withScope((scope) => {
          scope.setExtra("componentStack", errorInfo.componentStack);
          scope.setTag("errorType", error.name);
          scope.setLevel("error");

          // Add additional context about the error
          scope.setContext("error", {
            message: error.message,
            stack: error.stack,
            name: error.name,
          });

          // Add browser information
          scope.setContext("browser", {
            userAgent: navigator.userAgent,
            language: navigator.language,
            platform: navigator.platform,
          });

          Sentry.captureException(error);
        });
      }}
    >
      {children}
    </ErrorCatcher>
  );
};

// This component creates an actual error boundary using a class to catch render errors
class ErrorCatcher extends React.Component<{
  children: ReactNode;
  onError: (error: Error, info: { componentStack: string }) => void;
}> {
  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.props.onError(error, {
      componentStack: info.componentStack || "Unknown",
    });
  }

  render() {
    return this.props.children;
  }
}

export default AppErrorBoundary;
