import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import MortgageNote, {
  FetchNotesParams,
  SerializableFetchNotesParams,
} from "../interfaces/mortgageNote.redux.interface";
import { NavigateFunction } from "react-router-dom";

interface MortgageNotesState {
  notes: MortgageNote[];
  status: "idle" | "loading" | "failed";
  hasMore: boolean;
  filters: SerializableFetchNotesParams;
  searchQuery: string;
}

const initialState: MortgageNotesState = {
  notes: [],
  status: "idle",
  hasMore: true,
  filters: {
    status: ["Available"],
    addressState: [],
    occupancyStatus: [],
    propertyType: [],
    loanType: [],
    noteType: [],
    lienPosition: [],
    performanceStatus: [],
    initialLoanAmount: { min: null, max: null },
    currentLoanAmount: { min: null, max: null },
    loanPaymentAmount: { min: null, max: null },
    totalPayoff: { min: null, max: null },
    estimatedMarketValue: { min: null, max: null },
    borrowerDownPayment: { min: null, max: null },
    askingAmount: { min: null, max: null },
    interestRate: { min: null, max: null },
    loanTerm: { min: null, max: null },
    bedrooms: { min: null, max: null },
    bathrooms: { min: null, max: null },
    squareFootage: { min: null, max: null },
    originationDate: { start: null, end: null },
    maturityDate: { start: null, end: null },
    offset: 0,
    limit: 10,
  },
  searchQuery: "",
};

// Helper function to convert Date objects to ISO strings
const serializeDates = (
  filters: Partial<FetchNotesParams>
): Partial<SerializableFetchNotesParams> => {
  const { originationDate, maturityDate, ...rest } = filters;
  const serialized: Partial<SerializableFetchNotesParams> = { ...rest };

  if (originationDate) {
    serialized.originationDate = {
      start: originationDate.start?.toISOString() || null,
      end: originationDate.end?.toISOString() || null,
    };
  }

  if (maturityDate) {
    serialized.maturityDate = {
      start: maturityDate.start?.toISOString() || null,
      end: maturityDate.end?.toISOString() || null,
    };
  }

  return serialized;
};

// Helper function to convert ISO strings back to Date objects for API calls
export const deserializeDates = (
  filters: Partial<SerializableFetchNotesParams>
): Partial<FetchNotesParams> => {
  const { originationDate, maturityDate, ...rest } = filters;
  const deserialized: Partial<FetchNotesParams> = { ...rest };

  if (originationDate) {
    deserialized.originationDate = {
      start: originationDate.start ? new Date(originationDate.start) : null,
      end: originationDate.end ? new Date(originationDate.end) : null,
    };
  }

  if (maturityDate) {
    deserialized.maturityDate = {
      start: maturityDate.start ? new Date(maturityDate.start) : null,
      end: maturityDate.end ? new Date(maturityDate.end) : null,
    };
  }

  return deserialized;
};

export const fetchMortgageNotes = createAsyncThunk(
  "mortgageNotes/fetchMortgageNotes",
  async (filters: Partial<SerializableFetchNotesParams> = {}) => {
    const deserializedFilters = deserializeDates(filters);
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/mortgageNotes/incomeNote/fetch`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...deserializedFilters,
          status: ["Available"],
          limit: filters.limit || 10,
          offset: filters.offset || 0,
        }),
      }
    );
    const data = await response.json();
    return data as MortgageNote[];
  }
);

// Add a helper function to update URL params
const updateURLParams = (
  filters: Partial<SerializableFetchNotesParams>,
  navigate: NavigateFunction
) => {
  const queryParams = new URLSearchParams();

  if (filters.addressState?.length)
    queryParams.set("addressState", filters.addressState.join(","));
  if (filters.targetMin !== undefined)
    queryParams.set("targetMin", filters.targetMin.toString());
  if (filters.targetMax !== undefined)
    queryParams.set("targetMax", filters.targetMax.toString());
  if (filters.loanMin !== undefined)
    queryParams.set("loanMin", filters.loanMin.toString());
  if (filters.loanMax !== undefined)
    queryParams.set("loanMax", filters.loanMax.toString());
  if (filters.status?.length)
    queryParams.set("status", filters.status.join(","));
  if (filters.performanceStatus?.length)
    queryParams.set("performanceStatus", filters.performanceStatus.join(","));
  if (filters.occupancyStatus?.length)
    queryParams.set("occupancyStatus", filters.occupancyStatus.join(","));
  if (filters.propertyType?.length)
    queryParams.set("propertyType", filters.propertyType.join(","));
  if (filters.loanType?.length)
    queryParams.set("loanType", filters.loanType.join(","));
  if (filters.noteType?.length)
    queryParams.set("noteType", filters.noteType.join(","));
  if (filters.lienPosition?.length)
    queryParams.set("lienPosition", filters.lienPosition.join(","));

  // Handle numeric range parameters
  const handleRangeParam = (
    prefix: string,
    range: { min: number | null; max: number | null } | undefined
  ) => {
    if (range?.min !== null && range?.min !== undefined) {
      queryParams.set(`${prefix}Min`, range.min.toString());
    }
    if (range?.max !== null && range?.max !== undefined) {
      queryParams.set(`${prefix}Max`, range.max.toString());
    }
  };

  handleRangeParam("initialLoanAmount", filters.initialLoanAmount);
  handleRangeParam("currentLoanAmount", filters.currentLoanAmount);
  handleRangeParam("loanPaymentAmount", filters.loanPaymentAmount);
  handleRangeParam("totalPayoff", filters.totalPayoff);
  handleRangeParam("estimatedMarketValue", filters.estimatedMarketValue);
  handleRangeParam("borrowerDownPayment", filters.borrowerDownPayment);
  handleRangeParam("askingAmount", filters.askingAmount);
  handleRangeParam("interestRate", filters.interestRate);
  handleRangeParam("loanTerm", filters.loanTerm);
  handleRangeParam("bedrooms", filters.bedrooms);
  handleRangeParam("bathrooms", filters.bathrooms);
  handleRangeParam("squareFootage", filters.squareFootage);

  // Handle date ranges
  if (filters.originationDate?.start) {
    queryParams.set("originationDateStart", filters.originationDate.start);
  }
  if (filters.originationDate?.end) {
    queryParams.set("originationDateEnd", filters.originationDate.end);
  }
  if (filters.maturityDate?.start) {
    queryParams.set("maturityDateStart", filters.maturityDate.start);
  }
  if (filters.maturityDate?.end) {
    queryParams.set("maturityDateEnd", filters.maturityDate.end);
  }

  if (filters.searchQuery) queryParams.set("search", filters.searchQuery);

  navigate({ search: queryParams.toString() });
};

export const updateFiltersAndFetch = createAsyncThunk(
  "mortgageNotes/updateFiltersAndFetch",
  async (
    payload: {
      filters: Partial<FetchNotesParams>;
      navigate: NavigateFunction;
    },
    { dispatch, getState }
  ) => {
    const state = getState() as { mortgageNotes: MortgageNotesState };
    const serializedFilters = serializeDates(payload.filters);
    const updatedFilters = {
      ...state.mortgageNotes.filters,
      ...serializedFilters,
    };

    // Update URL parameters
    updateURLParams(updatedFilters, payload.navigate);

    // Update Redux state and fetch data
    dispatch(setFilters(updatedFilters));
    return dispatch(fetchMortgageNotes(updatedFilters)).unwrap();
  }
);

export const handleSearchAndFetch = createAsyncThunk(
  "mortgageNotes/handleSearchAndFetch",
  async (query: string, { dispatch, getState }) => {
    const state = getState() as { mortgageNotes: MortgageNotesState };

    // Only update if the search query has actually changed
    if (state.mortgageNotes.searchQuery !== query) {
      const updatedFilters: SerializableFetchNotesParams = {
        ...state.mortgageNotes.filters,
        addressState: [],
        targetMin: undefined,
        targetMax: undefined,
        loanMin: undefined,
        loanMax: undefined,
        status: ["Available"],
        offset: 0,
        limit: 10,
      };

      // Set search query separately since it's not part of FetchNotesParams
      dispatch(setFilters(updatedFilters));
      dispatch(setSearchQuery(query));
      return dispatch(
        fetchMortgageNotes({ ...updatedFilters, searchQuery: query })
      ).unwrap();
    }
    return Promise.resolve([]);
  }
);

export const mortgageNotesSlice = createSlice({
  name: "mortgageNotes",
  initialState,
  reducers: {
    setMortgageNotes: (state, action: PayloadAction<MortgageNote[]>) => {
      state.notes = action.payload;
    },
    addMortgageNotes: (state, action: PayloadAction<MortgageNote>) => {
      state.notes.push(action.payload);
    },
    appendMortgageNotes: (state, action: PayloadAction<MortgageNote[]>) => {
      state.notes = [...state.notes, ...action.payload];
    },
    setLoadingStatus: (state) => {
      state.status = "loading";
    },
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    setFailedStatus: (state) => {
      state.status = "failed";
    },
    setFilters: (
      state,
      action: PayloadAction<SerializableFetchNotesParams>
    ) => {
      state.filters = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMortgageNotes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMortgageNotes.fulfilled, (state, action) => {
        if (action?.meta?.arg?.offset === 0) {
          state.notes = action.payload;
        } else {
          state.notes = [...state.notes, ...action.payload];
        }
        state.status = "idle";
        state.hasMore =
          action.payload.length === (action?.meta?.arg?.limit || 10);
      })
      .addCase(fetchMortgageNotes.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {
  setMortgageNotes,
  addMortgageNotes,
  appendMortgageNotes,
  setLoadingStatus,
  setIdleStatus,
  setFailedStatus,
  setFilters,
  setSearchQuery,
} = mortgageNotesSlice.actions;

export default mortgageNotesSlice.reducer;
