// Helper to format numbers with commas and decimal places
export const formatCurrency = (value: string): string => {
  // Remove non-numeric characters except decimal point
  const cleanValue = value.replace(/[^\d.]/g, "");

  // Ensure only one decimal point
  const parts = cleanValue.split(".");
  const whole = parts[0];
  const decimal = parts[1]?.slice(0, 2) || "";

  // Add commas to whole number part
  const withCommas = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return decimal ? `${withCommas}.${decimal}` : withCommas;
};

// Helper to clean numeric input
export const cleanNumericInput = (
  value: string,
  allowDecimals = true
): string => {
  if (allowDecimals) {
    return value.replace(/[^\d.]/g, "");
  }
  return value.replace(/\D/g, "");
};

// Helper to format percentage input
export const formatPercentage = (value: string): string => {
  const cleaned = value.replace(/[^\d.]/g, "");
  const num = parseFloat(cleaned);
  if (isNaN(num)) return "";
  if (num > 100) return "100";

  // Allow up to 2 decimal places
  const parts = cleaned.split(".");
  if (parts[1]) {
    return `${parts[0]}.${parts[1].slice(0, 2)}`;
  }
  return cleaned;
};

// Validation functions
export const validateRequired = (value: any): string | null => {
  if (value === undefined || value === null || value === "") {
    return "This field is required";
  }
  return null;
};

export const validateMinValue = (value: number, min: number): string | null => {
  if (value < min) {
    return `Value must be at least ${min}`;
  }
  return null;
};

const addCommasToNumber = (value: string | number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatCurrencyInput = (value: string): string => {
  // Remove all non-digit characters except decimal point
  const cleanValue = value.replace(/[^\d.]/g, "");

  // Handle multiple decimal points
  const parts = cleanValue.split(".");
  if (parts.length > 2) {
    parts[0] = addCommasToNumber(parts[0]);
    return parts[0] + "." + parts.slice(1).join("");
  }

  // Add commas to the whole number part
  parts[0] = addCommasToNumber(parts[0]);

  // Limit decimal places to 2
  if (parts.length === 2) {
    return parts[0] + "." + parts[1].slice(0, 2);
  }

  return parts[0];
};

export const validateRange = (
  value: number,
  min: number,
  max: number
): string | null => {
  if (value < min || value > max) {
    return `Value must be between ${addCommasToNumber(
      min
    )} and ${addCommasToNumber(max)}`;
  }
  return null;
};

export const validatePastDate = (date: string): string | null => {
  const selectedDate = new Date(date);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (selectedDate >= today) {
    return "Date must be in the past";
  }
  return null;
};

export const validateFutureDate = (date: string): string | null => {
  const selectedDate = new Date(date);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (selectedDate <= today) {
    return "Date must be in the future";
  }
  return null;
};
